.sub_hero__main {
  color: var(--navy-blue);
  padding: 40px;
  background-color: white;
  align-items: center;
  border-radius: 40px;
  margin-top: 100px;
  margin-bottom: 136px;

  .sub__hero_heading {
    font-size: 75px;
    font-style: normal;
    font-family: var(--pp-formula-condensed);
    font-weight: 900;
    line-height: 97%;
    text-transform: uppercase;
    padding: 0px 30px 0px 0px !important;
  }

  .sub__hero_description {
    font-family: var(--PPEditorialOld);
    font-size: 35px;
    font-style: normal;
    font-weight: 200;
    line-height: 107%;
    padding-left: 30px;
    span{
      font-style: italic;
    }
    .second__description {
      font-family: var(--IBMPlexSans);
      font-size: 25px;
      font-weight: 400;
      line-height: 30px;
      letter-spacing: -0.55px;
      margin-top: 40px;
    }
  }
}

@media only screen and (min-width: 1728px) {
  .sub_hero__main {
    .sub__hero_heading {
      font-size: 77px;
    }
    .sub__hero_description {
      font-size: 44px;
    }
  }
}

@media only screen and (max-width: 1439px) {
  .sub_hero__main {
    .sub__hero_heading {
      font-size: 55px;
    }

    .sub__hero_description {
      font-size: 30px;
      line-height: 107%;
    }
  }
}

@media only screen and (max-width: 768px) {
  .sub_hero__main {
    padding: 40px 20px;
    margin-top: 60px;

    .sub__hero_heading {
      font-size: 55px;
      padding: 0px !important;
    }

    .sub__hero_description {
      font-size: 30px;
      line-height: 107%;
      padding: 0px !important;
      margin-top: 30px !important;
    }
  }
}

@media only screen and (max-width: 320px) {
  .sub_hero__main {
    padding: 40px 20px;

    .sub__hero_heading {
      font-size: 45px;
    }

    .sub__hero_description {
      font-size: 25px;
    }
  }
}