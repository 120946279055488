.privacy__main {
  width: 100%;
  background-color: #F5F5F5;

  .other__sections {
    width: 100%;
    padding: 43px 224px;
    color: var(--navy-blue);

    .privacy__heading {
      font-family: var(--pp-formula-condensed);
      font-size: 104px;
      font-weight: 900;
      line-height: 97%;
      text-transform: uppercase;
    }

    .content__text {
      font-family: var(--IBMPlexSans);
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px; /* 130% */
      letter-spacing: -0.44px;
      div,
      ul {
        margin-top: 20px;
      }
      .sub__heading {
        font-family: var(--pp-formula-condensed);
        font-size: 65px;
        font-weight: 900;
        line-height: 87%;
        text-transform: uppercase;
        margin-top: 49px;
      }
    }
  }
  .other__section {
    width: 100%;
    padding: 0px 224px;
  }
}

.border__none {
  border: none !important;
}

@media only screen and (max-width: 1439px) {
  .privacy__main {
    .other__section {
      padding: 0px 100px;
    }
    .other__sections {
      padding: 35px 100px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .privacy__main {
    width: 100%;
    .other__section {
      padding: 0px 35px;
    }
    .other__sections {
      padding: 20px 35px;
      .privacy__heading {
        font-size: 65px;
      }

      .content__text {
        font-size: 16px;
        div,
        ul {
          margin-top: 20px;
        }
        .sub__heading {
          font-size: 50px;
          font-weight: 700;
        }
      }
    }
  }
}

@media only screen and (max-width: 525px) {
}
