.navbar__main {
    height: 100px;
    padding: 20px 174px 0px;
    z-index: 1;

    .nav__border__section {
        width: 100%;
        padding: 10px 20px 10px 25px;
        border-radius: 50px;
        border: 1px solid #F5F5F5;
        background: #25253F;
        display: flex;
        height: 61px;
    }

    .link__section {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
        a, button {
            color: #F5F5F5;
            font-family: var(--IBMPlexMono);
            font-size: 13px;
            font-weight: 400;
            line-height: 22px;
            letter-spacing: 0.8px;
            text-transform: uppercase;
            text-decoration: none;
            border: none;
            outline: none;
            background-color: transparent;
            padding: 0;
            &:hover,&:active,&:focus{
                font-weight: 600;
              }
        }
    }

    .right__icon__section {
        display: flex;
        justify-content: flex-end;
        gap: 20px;
        align-items: center;

        .user__menu {
            position: absolute;
            top: 100px;
            width: 200px;
            height: 148px;
            background-color: var(--navy-blue);
            border: 1px solid var(--default-light);
            padding: 20px;
            display: flex;
            flex-direction: column;
            gap: 16px;
            z-index: 3;
        }

        .red__dot {
            background-color: #F04533;
            width: 10px;
            height: 10px;
            border-radius: 100%;
            position: absolute;
            top: 1.5px;
            right: 2.5px;
            border: 1px solid #F04533;
        }
    }
    .menu__icon{
        display: none;
    }

    &.light {
        .nav__border__section{
            background-color: white;
            border: 1px solid #25253F;
        }
        .link__section{
            a,button{
                color: #25253F;
            }
        }
        .user__menu{
            background-color: #FFF;
            border-color: var(--navy-blue);
        }
    }
    .connection__btn{
        border: none;
        outline: none;
        background-color: transparent;
        padding: 0px;
    }
}

@media only screen and (max-width:1439px){
    .navbar__main{
        padding: 20px 50px;
    }
}

@media only screen and (max-width:768px) {
    .navbar__main {
        padding: 10px 15px;
        height: 80px;
       .nav__border__section{
        justify-content: space-between;
        position: relative;
        height: auto;
       }
       .link__section,.right__section{
        display: none !important;
       }
       .logo__section{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
       }
       .menu__icon {
        display: block;
        position: absolute;
        left: 25px;
        top: 7px;
        z-index: 3;

        button{
          outline: none;
          border: none;
          background-color: transparent;
        }
      }
    }
}

