@font-face {
    font-family: 'IBMPlexSans';
    src: url('IBMPlexSans-Light.ttf');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'IBMPlexSans';
    src: url('IBMPlexSans-Regular.ttf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'IBMPlexSans';
    src: url('IBMPlexSans-Regular.ttf');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'IBMPlexSans';
    src: url('IBMPlexSans-SemiBold.ttf');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'IBMPlexSans';
    src: url('IBMPlexSans-Bold.ttf');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'IBMPlexSans';
    src: url('IBMPlexSans-Italic.ttf');
    font-weight: 400;
    font-style: italic;
}
