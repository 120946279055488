.swiper {
  width: 100%;
  height: 100%;
  padding: 0px 224px;
  .swiper-wrapper {
    gap: 0px;
  }
}

.swiper-slide {
  font-size: 18px;
  background: transparent;
  width: 350px !important;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.swiper {
  width: 100%;
  min-height: 300px;
  margin: 20px auto;
}

.review__card__main {
  width: 100%;
  border-radius: 25.128px;
  opacity: 0.2;
  &.active__custom {
    opacity: 1;
  }
  .review__img {
    border-radius: 25.128px;
    aspect-ratio: 175/262;
  }
  .review__section__new {
    position: absolute;
    bottom: 10px;
    width: 100%;
    padding: 0px 10px;

    .review__subsection {
      width: 100%;
      padding: 24px;
      min-height: 158px;
      border-radius: 15px;
      background: rgba(255, 255, 255, 0.4);
      backdrop-filter: blur(11px);
      font-family: var(--IBMPlexSans);

      .mesg,
      .name {
        font-size: 20px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: -0.44px;
      }
      .name {
        font-size: 18px;
        line-height: 20px;
        margin-top: 16px;
        text-transform: uppercase;
      }

      .address {
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
        text-transform: uppercase;
        color: rgba(8, 8, 40, 0.40);
      }
    }
  }
}

.arrow__section {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 0px;
  z-index: 98999999999999999;
  padding: 0px 132px;
  .btn-custom-new {
    width: 42px;
    height: 42px;
    background-color: var(--bg--yellow);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    border: none;
    outline: none;
    padding: 10.244px 10.244px 10.244px 7.683px;
    &.custom-next-button{
      padding:10px;
    }
    &:hover,&:active{
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.10) 0%, rgba(0, 0, 0, 0.10) 100%), #E4FF00;
    }
  }
}

@media only screen and (max-width:1439px){
  .swiper {
    padding: 0px 100px;
  }
}

@media only screen and (max-width:1024px){
  .swiper-slide {
    width: 321px !important;
  }
  .swiper {
    padding: 0px 100px;
  }
}

@media only screen and (max-width:768px){
  .swiper-slide {
    width: 321px !important;
  }
  .swiper {
    padding: 0px 80px;
  }
  .swiper-slide {
    width: inherit !important;
  }
  .review__card__main{
    .review__section__new{
      .review__subsection{
        .mesg,
      .name {
        font-size: 18px;
        line-height: 21px;
      }
      .name,.address {
        font-size: 16px;
        line-height: 17.5px;
      }
      }
    }
  }
  
}

@media only screen and (max-width:320px){
  .swiper-slide {
    width: 321px !important;
  }
  .swiper {
    padding: 0px 25px;
  }
  .swiper-slide {
    width: inherit !important;
  }
}
