@font-face {
    font-family: 'NeueHaasDisplay';
    src: url('NeueHaasDisplayMediu.ttf');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'NeueHaasDisplay';
    src: url('NeueHaasDisplayLight.ttf');
    font-weight: 300;
    font-style: normal;
}


@font-face {
    font-family: 'NeueHaasDisplay';
    src: url('NeueHaasDisplayBold.ttf');
    font-weight: bold;
    font-style: normal;
}

