@import './theme/styles/global.scss';
@import './theme/fonts/karla/stylesheet.css';
@import './theme/fonts/Inconsolata/stylesheet.css';
@import './theme/fonts/Inria_Serif/stylesheet.css';
@import './theme/fonts/SfProText/stylesheet.css';
@import './theme/fonts/Circular/stylesheet.css';
@import './theme/fonts/PPFormula/stylesheet.css';
@import './theme/fonts/Inter/stylesheet.css';
@import './theme/fonts/PlusJakatra/stylesheet.css';
@import './theme/fonts/NeueHaasGroteskTextPro/stylesheet.css';
@import './theme/fonts/IBMPlexMono/stylesheet.css';
@import './theme/fonts/PPEditorialOld/stylesheet.css';
@import './theme/fonts/IBMPlexSans/stylesheet.css';



body {
  font-family: var(--primary-font);
  color: black;
}