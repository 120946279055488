@font-face {
    font-family: 'PPEditorialOld';
    src: url('PPEditorialOld-Ultralight.ttf');
    font-weight: 300;
    font-style: normal;
}


@font-face {
    font-family: 'PPEditorialOld';
    src: url('PPEditorialOld-UltralightItalic.ttf');
    font-weight: 400;
    font-style: italic;
}
