.custom__black__btn {
	background-color: var(--default-dark);
	border-radius: 0;
	font-weight: 400;
	font-size: 16px;
	color: #FFFFFF;
	min-height: 44px;
	text-align: center;
	min-width: 124px;

	&:hover {
		text-decoration: none;
	}
}

.btn__gray,
.btn__gray:disabled {
	background-color: #D9DCE1 !important;
	border-radius: 0;
	font-weight: 400;
	font-size: 14px;
	color: #757575;
	min-width: 124px;
	text-align: center;
	border-color: #D9DCE1;

	&:hover {
		text-decoration: none;
	}

	&:active,
	&:focus {
		border: none !important;
		outline: none !important;
		background-color: #e6e8ec;
	}
}

.btnWithoutOutline {
	font-family: var(--IBMPlexMono);
	font-size: 16px;
	font-weight: 400;
	line-height: 22px;
	letter-spacing: 0.8px;
	text-transform: uppercase;
	border: none;
	outline: none;
	background-color: transparent;
	text-align: start;

	a {
		cursor: pointer;
		text-decoration: none;
		font-family: var(--IBMPlexMono);
		font-size: 16px;
		font-weight: 400;
		line-height: 22px;
		letter-spacing: 0.8px;
		text-transform: uppercase;

		&:hover {
			cursor: pointer;
		}
	}
}

.btn__with__outline {
	font-size: 13px;
	cursor: pointer;
	border: 1px solid white;
	background-color: transparent;
	border-radius: 4px;
	padding: 11px 21.5px;
	color: white;
	font-weight: 400;
	display: flex;
	align-items: center;
	justify-content: center;

	@media (max-width: 525px) {
		padding: 10px 15px;
	}
}

.outlined__btn {
	border: none;
	outline: none;
	padding: 8px 20px 9px 20px;
	border-radius: 60px;
	border: 1px solid var(--navy-blue);
	background-color: white;
	font-family: var(--IBMPlexMono);
	text-transform: uppercase;

	&.bg__yellow {
		background: var(--bg--yellow);
	}

	&.no_bg_no_border {
		outline: none;
		border: 1px solid transparent;
		background-color: transparent;
	}

	&:hover,
	&:active {
		background: linear-gradient(0deg, rgba(0, 0, 0, 0.10) 0%, rgba(0, 0, 0, 0.10) 100%), #FFF;

		&.bg__yellow {
			background: linear-gradient(0deg, rgba(0, 0, 0, 0.10) 0%, rgba(0, 0, 0, 0.10) 100%), #E4FF00;
		}
		&.dark__on__hover{
			background: inherit;
			font-weight: 600;
		}
	}
}