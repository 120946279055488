.review__section__main {
    width: 100%;
    text-align: center;
    color: var(--navy-blue);
    margin-bottom: 171px;
    margin-top: 201px;

    .review__description {
        text-align: center;
        font-family: var(--IBMPlexSans);
        font-size: 25px;
        font-weight: 400;
        line-height: 30px;
        max-width: 621px;
        margin: 24px auto;
    }

    .review__images__section {
        margin-top: 44px;

        img {
            width: 64.857px;
            height: 64.857px;
            border-radius: 100%;
            border: 1.5px solid #FFF;

            &.not_first {
                margin-left: -24px;
            }
        }

        .text__after__review {
            font-family: var(--IBMPlexSans);
            font-size: 20px;
            font-weight: 400;
            line-height: 30px;
            opacity: 0.6;
            margin-top: 10px;
        }
    }
}

@media only screen and (max-width:768px){
    .review__section__main{
        margin-top: 131px;
        .review__description{
            font-size: 18px;
            line-height: 22px;
        }
    }
}