// .address {
//     font-size: 15px;
//     font-weight: 400;
//     margin-left: 40px;
//     margin-bottom: 0px !important;
//     font-family: var(--secondary-font);
//     line-height: 15.74px;

//     @media (max-width:767px) {
//         margin: 0;
//     }
// }

// .connected__badge {
//     color: #0DD096;
//     font-size: 14px;
//     font-weight: 400;
// }

// .connect__btn {
//     min-height: 40px !important;
//     line-height: 16px;
// }

// .cnfirm__del__container {
//     display: flex;
// }

// .image__section {
//     width: 54%;
// }

// .details__section {
//     width: 46%;

//     .user__details__section {
//         padding: 0px 30px 0px 40px;

//         .user__name {
//             font-size: 34px;
//             font-weight: 800;
//             line-height: 39.75px;
//         }

//         .fund__raise {
//             font-size: 15px;
//             font-weight: 400;
//             font-style: italic;
//             font-family: var(--pre-secondary-font);
//             margin-top: 16px;
//         }

//         .user__data__table {
//             width: 100%;
//             margin-top: 20px;
//             border-collapse: collapse;
//             table-layout: fixed;
//             font-family: var(--secondary-font);

//             .row__container {
//                 border-bottom: 1px solid var(--default-dark);
//                 padding: 7px 0;
//                 width: 100%;

//                 .table__ques {
//                     width: 210px;
//                     font-size: 15px;
//                 }

//                 .table__ans {
//                     .preffered {
//                         margin-left: 15px;
//                     }

//                     a {
//                         font-size: 15px;
//                         font-weight: 400;
//                     }
//                 }
//             }

//             .border__top {
//                 border-top: 1px solid var(--default-dark);
//             }

//         }

//         .user__about {
//             .about__question {
//                 font-weight: 600;
//                 font-size: 15px;
//                 line-height: 16px;
//                 font-family: Inconsolata;
//                 margin-top: 18px;
//                 margin-bottom: 7px !important;
//             }

//             .about__answer {
//                 font-style: normal;
//                 font-weight: 400;
//                 font-size: 15px;
//                 line-height: 18px;
//                 margin-bottom: 0px !important;
//             }
//         }

//         @media (max-width:767px) {
//             padding: 0px;
//         }
//     }
// }

// .spinner__container {
//     width: 100%;
//     min-height: calc(100vh - 211px);
//     display: flex;
//     align-items: center;
//     justify-content: center;
// }


// .image__section {
//     width: 54%;

//     .profile__image {
//         aspect-ratio: 736 / 825;
//         object-fit: cover;
//     }
// }

// @media only screen and (max-width:768px) {
//     .address {
//         margin-left: 0px;
//     }

//     .details__section {
//         width: 100%;
//         padding-bottom: 20px !important;

//         .user__details__section {
//             padding: 0px;
//         }
//     }

//     .image__section {
//         width: 100%;
//     }

//     .connect__city {
//         margin-top: 20px;
//     }
// }

.profile__content__section {
  width: 550px;
  padding-bottom: 20px;

  .personal__info__section {
    width: 100%;
    border: 1px solid black;
    display: flex;

    .profile__image__section {
      width: 24.7273%;
    }

    img {
      width: 100%;
      aspect-ratio: 34 / 39;
      object-fit: cover;
    }

    .user__detail__section {
      width: 75.2727%;
      // padding-left: 21px;

      .connect__btn {
        min-height: 37px !important;
        line-height: 16px;
        max-width: 102px;
        min-width: 102px;
        font-size: 13px;
      }

      .user__name {
        font-size: 18px;
        font-weight: 800;
        line-height: 18px;
        margin-top: 17px;
        padding-left: 21px;

        .address {
          font-size: 13px;
          font-weight: 400;
          margin-left: 5px;
          margin-bottom: 0px !important;
          font-family: var(--secondary-font);
          line-height: normal;

          @media (max-width: 767px) {
            margin: 0;
          }
        }
      }

      .connected__badge {
        color: #080828;
        font-size: 14px;
        font-weight: 400;
        border-radius: 60px;
        border: 1px solid #080828;
        background: #e4ff00;
      }

      .fund__raise {
        font-size: 13px;
        font-weight: 400;
        font-style: italic;
        font-family: var(--pre-secondary-font);
        margin-top: 12px;
        padding-left: 21px;
      }

      .user__data__table {
        width: 100%;
        border-collapse: collapse;
        table-layout: fixed;
        font-family: var(--secondary-font);
        margin-top: 6px;

        .row__container {
          padding: 7px 0;
          width: 100%;
          padding-left: 21px;

          .table__ques {
            width: 160px;
            font-size: 15px;
          }

          .table__ans {
            a {
              font-size: 15px;
              font-weight: 400;
            }
          }
        }

        .border__top {
          border-top: 1px solid rgba(0, 0, 0, 0.2);
        }
      }
    }
  }

  .user__about {
    margin-top: 8px;

    .about__question {
      font-weight: 600;
      font-size: 15px;
      line-height: 16px;
      font-family: Inconsolata;
      margin-top: 24px;
      margin-bottom: 7px !important;
    }

    .about__answer {
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      margin-bottom: 0px !important;
    }
  }

  .req__btn__section {
    margin-top: 35px;

    .connect__btn {
      min-height: 50px !important;
      line-height: 16px;
      font-size: 14px;
    }

    .delete__btn {
      font-size: 14px;
      text-align: center;
    }
  }
}

.email_underline{
    text-decoration: underline;
    color: var(--navy-blue);
    font-family: var(--PPEditorialOld);
    font-size: 30px;
    font-style: italic;
    font-weight: 200;
    line-height: 30px;
    letter-spacing: -0.66px;
}
.img__box {
  background-image: url("../../assets/images/svgImages/userImageBg.svg");
  background-size: 100% auto;
  background-position: bottom;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  max-width: 480px;
  width: 100%;
  height: 313.693px;
  flex-shrink: 0;
  img{
    max-width: 80%;
    object-fit: cover;
  }
}

.text__section {
  padding: 30px 20px;
  background-color: white;
  max-width: 480px;
  width: 100%;

  .user_name {
    font-family: var(--pp-formula-condensed);
    font-style: normal;
    font-weight: 900;
    line-height: 87%;
    text-transform: uppercase;
    color: var(--navy-blue);
    font-size: 55px;
  }

  .user_des {
    font-family: var(--PPEditorialOld);
    font-style: italic;
    font-weight: 200;
    margin-top: 20px;
    color: var(--navy-blue);
    font-size: 27px;
    line-height: 30px;
    letter-spacing: -0.616px;
  }

  .details {
    font-family: var(--IBMPlexSans);
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    padding-left: 30px;
    border-left: 2px solid var(--navy-blue);
  }
  .connected__badge {
    color: var(--navy-blue);
    font-size: 15px;
    font-weight: 600;
    border-radius: 60px;
    border: 1px solid var(--navy-blue);
    background: #e4ff00;
    display: inline-flex;
    padding: 8px 24px 9px 24px;
    align-items: flex-start;
    gap: 10px;
  }
}

.section__heading {
  font-family: var(--pp-formula-condensed);
  font-style: normal;
  font-weight: 900;
  line-height: 87%;
  text-transform: uppercase;
  color: var(--navy-blue);
  font-size: 55px;
}
.list_ {
  display: flex;
  gap: 15px;
  margin-top: 15px;

  .bullet {
    width: 20px;
    height: 20px;
    background-color: #0078f3;
    border-radius: 100%;
  }

  .bullet__point {
    font-family: var(--PPEditorialOld);
    font-size: 30px;
    font-style: normal;
    font-weight: 200;
    line-height: 30px;
    letter-spacing: -0.66px;
    color: var(--navy-blue);
  }
}
.media_content {
  color: var(--navy-blue);
  font-family: var(--IBMPlexSans);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 130% */
  letter-spacing: -0.44px;
}

.img__width {
  max-width: 100%;
}

.facts__container {
  max-width: 100%;
}
.decline_btn {
  color: #f04533;
  font-family: var(--IBMPlexMono);
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.55px;
  text-transform: uppercase;
  background-color: white;
  border: none;
  margin-left: 15px;
}

.font_italic{
    font-style: italic;
}

@media only screen and (max-width: 525px) {
  .profile__content__section {
    width: 100%;
    .personal__info__section {
      display: block;
      .profile__image__section {
        width: 100%;
        img {
          aspect-ratio: 3.53/2.94;
        }
      }
      .user__detail__section {
        width: 100%;
        .user__name,
        .fund__raise,
        .row__container {
          padding-left: 18px;
        }
        .user__name {
          margin-bottom: 12px;
        }
        .fund__raise {
          margin-top: 0px;
        }
        .row__container {
          .table__ques {
            width: 138px !important;
          }
        }
      }
    }
  }

  .cnfirm__del__container {
    display: block;

    .delete__btn {
      margin-top: 10px;
    }
  }

  .img__box {
    max-width: 100%;
  }
  .facts__container {
    margin: 0px 20px 0px 20px;
  }
  .text__section {
  }
}
