.slider__section {
  margin-top: 176px;
  .switch__custom {
    margin-top: 32px;
  }
  .button__main__section {
    margin-top: 32px;
    border-radius: 100px;
    border: 4px solid #fff;
    background: #fff;
    display: flex;
    justify-content: center;

    .pading__setting {
      padding-top: 60px !important;
    }
    .no_bg_no_border {
      outline: none;
      border: 1px solid transparent;
      background-color: transparent;
    }
  }
}

.trusted__section__main {
  .icons__area {
    margin-top: 54px;
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding: 0px 50px;
    .trusted__icons__section {
      padding: 0px 85px;
      justify-content: space-between;
      img {
        max-width: 73px;
      }
    }
  }
}

.intro__card__section__companies {
  width: 100%;
  margin-top: 92px;
  padding: 0px 224px;

  .intro__card {
    margin-top: 152px !important;
    display: flex;
    justify-content: space-between;

    .card__img__section {
      img {
        width: 100%;
        aspect-ratio: 1/1;
        border-radius: 33px;
      }
    }
    .card__img__section {
      width: calc(50% - 15px);
      padding: 0px;
      margin: 0px;
      img {
        width: 100%;
        aspect-ratio: 1/1;
      }
    }

    .card__text__section {
      width: calc(50% - 15px);
      padding: 0px;
      margin: 0px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      justify-content: center;

      .card__main {
        border-radius: 40px;
        background: #fff;
        padding: 30px;
        max-height: 103px;
        transition: max-height .5s;

        &.opened {
          background-color: var(--bg--yellow);
          max-height: 500px;
          overflow: hidden;
        }
        .heading__section {
          gap: 15px;
        }
      }

      .count {
        font-family: var(--pp-formula-condensed);
        font-size: 40px;
        font-weight: 900;
        line-height: 87%;
        text-transform: uppercase;
      }
      .card__heading {
        font-family: var(--PPEditorialOld);
        font-size: 40px;
        font-style: italic;
        font-weight: 200;
        line-height: 107%;
      }

      .card__description {
        font-family: var(--IBMPlexSans);
        font-size: 25px;
        font-weight: 400;
        line-height: 30px;
        letter-spacing: -0.55px;
      }
    }
  }
}

@media only screen and (max-width: 1439px) {
  .trusted__section__main {
    .icons__area {
      .trusted__icons__section {
        padding: 0px;
      }
    }
  }
  .intro__card__section__companies {
    padding: 0px 100px;
    .intro__card {
    }
  }
}

@media only screen and (max-width: 768px) {
  .intro__card__section__companies {
    padding: 0px;
  }
  .slider__section{
    margin-top: 109px;
  }
}
