.ant-drawer {
    .ant-drawer-wrapper-body {
        font-family: var(--primary-font) !important;

        .ant-drawer-header {
            border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        }

        .ant-drawer-body {
            padding: 0px !important;
        }
    }

    .ant-drawer-footer {
        padding: 0px;
    }
}

.drawer__header {
    padding: 27px 30px;
}

.content__section {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 0px 20px 20px 20px;

    h2 {
        color: white;
        font-size: 34px;
        font-weight: 800;
        margin-bottom: 0px !important;
    }

    div,
    a {
        color: white;
        font-size: 16px;
        cursor: pointer;
        text-decoration: none;

        &:hover {
            color: white;
            cursor: pointer;
        }
    }
}

.drawer__footer {
    width: 100%;
    height: 70px;
    border-top: 1px solid white;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
}

.title__container {
    display: flex;
    align-items: center;
    gap: 20px;
    color: #1A1F36;
    font-family: var(--pp-formula-condensed);
    font-size: 17px;
    font-weight: 900;
    line-height: normal;

    &.search__title__container {
        color: #1a1f36;
    }

    .center__line {
        width: 24px;
        height: 0px;
        border: 1px solid #d9dce1;
        transform: rotate(90deg);
    }
}

.connection__tabs {
    display: flex;
    gap: 20px;
    align-items: center;
    padding: 23px 79px;
}

.not__found__container {
    width: 100%;
    min-height: calc(100% - 90px);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 80px;

    .not__found__heading {
        color: var(--navy-blue);
        font-family: var(--PPEditorialOld);
        font-size: 45px;
        font-weight: 200;
        line-height: 100%;
    }

    .not__found__description {
        color: var(--navy-blue);
        font-family: var(--IBMPlexSans);
        font-size: 18px;
        font-weight: 400;
        line-height: 22px;
        margin-top: 24px !important;
    }
}

.new__drawer__main {
    width: 100%;
    height: 100%;
    background-color: white !important;
    padding: 30px 25px;
    border-radius: 20px;
    border: 1px solid var(--navy-blue);
    position: relative;
}

.content__section__new {
    padding: 15px;
    height: 100%;

    .Link___section {
        display: flex;
        padding: 30px 0px;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 10px;
        margin-top: 40px;
        align-self: stretch;
        border-top: 1px solid var(--navy-blue);
        border-bottom: 1px solid var(--navy-blue);
        color: var(--navy-blue);

        div,
        a,
        button {
            font-family: var(--IBMPlexMono);
            font-size: 25px;
            font-weight: 300;
            line-height: 32px;
            letter-spacing: 1.25px;
            text-transform: uppercase;
            text-decoration: none;
            outline: none;
            border: none;
            background-color: transparent;
            padding: 0px;
        }

        .drawer__dropdown {
            padding: 10px 0px 20px 30px;

            .list__main {
                gap: 15px;


                .bullet {
                    width: 12px;
                    height: 12px;
                    border-radius: 100%;
                }

                .list__text {
                    font-family: var(--IBMPlexMono);
                    font-size: 20px;
                    font-weight: 400;
                    line-height: 32px;
                    letter-spacing: 1px;
                    text-transform: uppercase;
                }
            }
        }
    }

    .Button__section {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
}

.drawer__footer_new {
    width: 100%;

    .Button__section {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
}

@media only screen and (max-width: 767px) {
    .navbar__link__btn {
        font-size: 16px;
        border: none;
        outline: none;

        &:focus,
        &:active {
            border: none;
            outline: none;
        }

        .connection__length {
            font-weight: 700;
        }
    }
}

.search__bar__main {
    width: 100%;
    display: flex;
    align-items: center;
    height: 41px;
    // border-bottom: 1px solid #EEEEEE;

    .navbar__input {
        width: 100%;
        height: 41px;
        border-color: #eeeeee;
        color: #000;
        font-family: var(--IBMPlexSans);
        font-size: 17px;
        font-weight: 400;
    }
}

.search__result__section {
    width: 100%;
    min-height: calc(100% - 41px);

    &.empty {
        display: flex;
        align-items: center;
        justify-content: center;

        .search__container {
            text-align: center;
            width: 321px;

            .no__search {
                color: var(--navy-blue);
                text-align: center;
                font-family: var(--PPEditorialOld);
                font-size: 45px;
                font-weight: 200;
                line-height: 100%;
                text-align: center;

                span {
                    font-style: italic;
                }
            }

            .no__search__description {
                color: var(--navy-blue);
                text-align: center;
                font-family: var(--IBMPlexSans);
                font-size: 18px;
                font-weight: 400;
                line-height: 22px;
                margin-top: 31px;
            }

            .something__else__btn {
                display: flex;
                padding: 8px 27px;
                border-radius: 60px;
                border: 1px solid var(--navy-blue);
                background: #FFF;
                font-family: var(--IBMPlexMono);
                font-size: 16px;
                font-weight: 500;
                line-height: 22px;
                letter-spacing: 0.8px;
                text-transform: uppercase;
                margin-top: 23px;
            }
        }
    }
}

@media only screen and (max-width:525px) {
    .connection__tabs {
        padding: 23px 40px;
    }
}

@media only screen and (max-width:320px) {
    .content__section__new {
        .Link___section {
            .drawer__dropdown {
                padding: 10px 0px 20px 0px;
            }

            div,
            a,
            button {
                font-size: 21px;
            }
        }
    }
}