@import "bootstrap/dist/css/bootstrap.min.css";
@import "antd/dist/reset.css";

// variables
:root {
  --primary-font: "Karla";
  --default-dark: #000;
  --default-light: #fff;
  --default-gray: #eeeeee;
  --dark-gray:#252525;
  --primary-color: #01aef0;
  --secondary-color: #fff;
  --font-base: 1rem;
  --error: #ff0000;
  --success: #5cb85c;
  --secondary-font: "Inconsolata";
  --pre-secondary-font: "InriaSerif";
  --sfpro: "SfProText";
  --purple: #1A1F36;
  --logo-font: "Circular";
  --inter-font:'Inter';
  --sky-blue:#4FB9DA;
  --pp-formula:'PPFormula2';
  --jakarta:'PlusJakartaSans';
  --cream:#F0E8DA;
  --new-has-font:'NeueHaasDisplay';
  --light-black:#151515;
  --IBMPlexMono:"IBMPlexMono";
  --IBMPlexSans:"IBMPlexSans";
  --PPEditorialOld:"PPEditorialOld";
  --navy-blue:#080828;
  --pp-formula-condensed:"PPFormula";
  --bg--yellow:#E4FF00;
}

// variables


a {
  text-decoration: underline;
  font-size: 14px;
  font-weight: 400;
  line-height: 14.5px;
  background-color: transparent;
  color: var(--default-dark);

  &:hover {
    color: var(--default-dark);
    cursor: pointer;
  }
}

.navbar__drawer {
  .ant-drawer-close {
    display: none !important;
  }
}

.navbar__input {
  min-height: 36px !important;
  padding-right: 30px !important;

  @media (max-width:767px) {
    min-height: 44px !important;
  }
}

.pointer {
  cursor: pointer;
}
:where(.css-dev-only-do-not-override-k7429z).ant-notification .ant-notification-notice .ant-notification-notice-icon{
  top: 36%;
}

.font__inter{
font-family: var(--inter-font);
}

.other__section{
  width: 100%;
  padding: 0px 224px;
}


@media only screen and (max-width:1439px){
      .other__section{
          padding: 0px 100px;
      }
}

@media only screen and (max-width:768px){
      .other__section{
          padding: 0px 35px;
      }
}

@media only screen and (max-width:380px){
      .other__section{
          padding: 0px 7px;
      }
}