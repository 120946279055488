@font-face {
    font-family: 'IBMPlexMono';
    src: url('IBMPlexMono-Light.ttf');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'IBMPlexMono';
    src: url('IBMPlexMono-Regular.ttf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'IBMPlexMono';
    src: url('IBMPlexMono-Medium.ttf');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'IBMPlexMono';
    src: url('IBMPlexMono-SemiBold.ttf');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'IBMPlexMono';
    src: url('IBMPlexMono-Bold.ttf');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'IBMPlexMono';
    src: url('IBMPlexMono-Italic.ttf');
    font-weight: 400;
    font-style: italic;
}
