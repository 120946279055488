.home__new__main{
    background-color: #F5F5F5;
    color: var(--navy-blue);
    .other__section{
        width: 100%;
        padding: 0px 224px;
    }
    .heading__text{
        font-family: var(--PPEditorialOld);
        font-size: 65px;
        font-style: italic;
        font-weight: 200;
        line-height: 100%;
        text-align: center;

        span{
            font-style: normal;
        }
    }
}

@media only screen and (max-width:1439px){
    .home__new__main{
        .other__section{
            padding: 0px 100px;
        }
        .heading__text{
            font-size: 55px;
            line-height: 100%;
        }
    }
}

@media only screen and (max-width:768px){
    .home__new__main{
        .other__section{
            padding: 0px 35px;
        }
        .heading__text{
            font-size: 65px;
            line-height: 100%;
            &.start__meeting{
                font-size: 45px;
            }
        }
    }
}

@media only screen and (max-width:380px){
    .home__new__main{
        .other__section{
            padding: 0px 7px;
        }
        .heading__text{
            font-size: 45px;
            line-height: 100%;
        }
    }
}