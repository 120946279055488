@font-face {
    font-family: 'SfProText';
    src: url('SFPRODISPLAYREGULAR.OTF');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'SfProText';
    src: url('SFPRODISPLAYBOLD.OTF');
    font-weight: bold;
    font-style: normal;
}