.new__navbar__main {
  width: 100%;
  padding: 20px 174px;
  color: var(--navy-blue);
  .menu__icon {
    display: none;
  }

  .nav__section {
    width: 100%;
    padding: 10px 10px 10px 25px;
    border-radius: 50px;
    border: 1px solid var(--navy-blue);
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: var(--IBMPlexMono);
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
  }

  .links__section {
    gap: 25px;
    a,div {
      font-family: var(--IBMPlexMono);
      font-size: 16px;
      line-height: 22px;
      font-weight: 400;
      text-decoration: none;
      color: var(--navy-blue);
      &:hover,&:active,&:focus{
        font-weight: 600;
      }
    }
    .dropdown__btn {
      border: none;
      outline: none;
      background-color: transparent;
      padding: 0px;
      text-transform: uppercase;
      &:hover,&:active,&:focus{
        font-weight: 600;
      }
    }

    .nav__drop__down {
      width: 288px;
      border-radius: 20px;
      background-color: #f5f5f5;
      box-shadow: 0px 9px 44px 0px rgba(8, 8, 40, 0.5);
      display: inline-flex;
      padding: 15px;
      flex-direction: column;
      gap: 5px;
      position: absolute;
      top: 60px;
      left: 10px;

      .option__card {
        padding: 10px;
        background-color: #fff;
        display: flex;
        gap: 8px;
        .bullet {
          width: 12px;
          height: 12px;
          border-radius: 100%;
          background-color: red;
        }
        .name {
          color: #000;
          font-family: var(--IBMPlexMono);
          font-size: 14px;
          font-weight: 500;
          line-height: 22px;
          margin-top: -5px;
          letter-spacing: 0.7px;
          text-transform: uppercase;
        }
        .description {
          color: #000;
          font-family: var(--IBMPlexSans);
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
          margin-top: 5px;
        }
      }
    }
  }

  .btns__section {
    display: flex;
    gap: 20px;
  }
}

@media only screen and (max-width: 1439px) {
  .new__navbar__main {
    padding: 20px 50px;
    .nav__section {
      font-size: 13px;
    }
    .logo__section {
      img {
        max-width: 100px;
      }
    }
    .links__section {
      gap: 20px;
      a, div, .dropdown__btn {
        font-size: 13px;
      }
    }
  }
  .btns__section {
    gap: 15px;
  }
}

@media only screen and (max-width: 768px) {
  .new__navbar__main {
    padding: 10px 15px;
    .nav__section {
      position: relative;
      .menu__icon {
        display: block;
        position: absolute;
        left: 25px;
        button{
          outline: none;
          border: none;
          background-color: transparent;
        }
      }
      .logo__section {
        width: 100%;
        text-align: center;
        img {
          max-width: 100px;
        }
      }
      .links__section,
      .btns__section {
        display: none !important;
      }
    }
  }
}

.ant-dropdown-menu, .ant-dropdown-menu-root{
  width: 288px;
  border-radius: 20px !important;
  background-color: #f5f5f5 !important;
  box-shadow: 0px 9px 44px 0px rgba(8, 8, 40, 0.5) !important;
  display: inline-flex;
  padding: 15px !important;
  flex-direction: column;
  gap: 5px;
  position: absolute;
  top: 22px;
  left: 10px;
}
.ant-dropdown-menu-item{
  background-color: white !important;
  a{
    text-decoration: none !important;
  }
  .option__card {
    padding: 10px;
    display: flex;
    gap: 8px;
    .bullet {
      width: 12px;
      height: 12px;
      border-radius: 100%;
      background-color: red;
    }
    .name {
      color: #000;
      font-family: var(--IBMPlexMono) !important;
      font-size: 14px !important;
      font-weight: 500 !important;
      line-height: 22px !important;
      margin-top: -5px;
      letter-spacing: 0.7px;
      text-transform: uppercase;
      text-decoration: none !important;
    }
    .description {
      color: #000  !important;
      font-family: var(--IBMPlexSans)  !important;
      font-size: 16px  !important;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      margin-top: 5px;
      text-decoration: none !important;
    }
  }
  &:hover{
    background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.1) 0%,
        rgba(0, 0, 0, 0.1) 100%
      ),
      #fff !important;
  }
}