@font-face {
    font-family: 'PPFormula2';
    src: url('PPFormula-ExtendedMedium.otf');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'PPFormula2';
    src: url('PPFormula-MediumItalic.otf');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'PPFormula2';
    src: url('PPFormula-SemiExtendedBold.otf');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'PPFormula2';
    src: url('PPFormula-SemiCondensedExtralightItalic.otf');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'PPFormula';
    src: url('PPFormula-CondensedRegular.woff2') format('woff2'), /* Modern Browsers */
         url('PPFormula-CondensedRegular.woff') format('woff'), /* Modern Browsers */
         url('PPFormula-CondensedRegular.ttf') format('truetype'); /* Safari, Android, iOS */
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'PPFormula';
    src: url('PPFormula-CondensedBlack.woff2') format('woff2'), /* Modern Browsers */
         url('PPFormula-CondensedBlack.woff') format('woff'), /* Modern Browsers */
         url('PPFormula-CondensedBlack.ttf') format('truetype'); /* Safari, Android, iOS */
    font-weight: 900;
    font-style: normal;
}


 
