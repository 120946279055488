.form-control {
    border: 1px solid rgba(0, 0, 0, 0.2);
    min-height: 44px;
    border-radius: 0px;
    color: var(--default-dark);
    ;
    padding: 5px;
    font-size: 16px;
    line-height: 19px;

    &:focus {
        border-color: var(--default-dark);
        box-shadow: none;
    }

    &[type='number']::-webkit-inner-spin-button,
    &[type='number']::-webkit-outer-spin-button {
        -webkit-appearance: none;
        -moz-appearance: none;
        margin: 0;
    }

    &::placeholder {
        color: #757575;
    }
}

.form-control:disabled,
.form-control[readonly] {
    background-color: #f7f7f7;
    // padding-top: 28px;
}

textarea.form-control {
    resize: none;
    min-height: 215px;
}

.text__area__length {
    font-size: 14px;
    font-weight: 400;
}

select.form-control {
    color: #757575;
    background-image: url("../../assets/icons/arrow-down.svg");
    background-repeat: no-repeat;
    background-position: right 12px center;
    padding-left: 16px;
}

.custom__select {
    background-color: rgba(0, 0, 0, 0.05);
    background-image: url("../../assets/icons/arrow-down.svg");
    background-repeat: no-repeat;
    background-position: right 12px center;
    border: none;
    outline: none;
    padding: 2px 28px 3.5px 7px;
    min-height: 41px;
    width: 174px;
    color: #000 !important;
    font-family: var(--IBMPlexSans);
    font-size: 17px;
    font-weight: 400;

    &:hover,
    &:focus,
    &:active {
        background-color: rgba(0, 0, 0, 0.05);
    }

    @media (max-width:767px) {
        min-height: 44px;
        font-size: 14px;
    }
}

.react-tel-input {
    width: 100%;
    padding: 0px;

    .flag-dropdown {
        width: 20% !important;
        background-color: var(--default-dark);
        border: none !important;
        border-radius: 0px !important;
        height: 44px;
    }

    .form-control {
        border-radius: 0px !important;
        width: 80%;
        margin-left: 20%;
        padding: 5px 5px 5px 10px;
        font-family: var(--primary-font);
    }

    .selected-flag {
        width: 100%;

        &:hover,
        &:active,
        &:focus {
            background-color: var(--default-dark);
        }
    }

    .flag-dropdown.open {
        .selected-flag {
            background-color: var(--default-dark);
            width: 100% !important;
        }
    }

    .flag {
        width: 17px;
    }

    .arrow {
        display: none !important;
    }

    @media (max-width:525px) {
        .flag-dropdown {
            width: 25% !important;
        }
    }
}

.upload__file {
    border: 1px dashed rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 13.5px 12px 13.5px 16px;
    height: 121px;
    position: relative;


    label {
        font-weight: 400;
        font-size: 16px;
        line-height: 40px;
        text-align: center;
        color: #000000;
        margin-bottom: 16px;
    }

    input {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
    }

    .upload__btn {
        height: 28px;
    }
}

.dial__code {
    font-size: 15px;
    color: white;
    display: flex;
    height: 44px;
    align-items: center;
    margin-left: 20px;
    width: 50px;

    .icon__container {
        margin-top: -3px;
    }
}

.num__input {
    width: 80%;
    margin-left: 20%;

    @media (max-width:525px) {
        width: 75%;
        margin-left: 25%;

    }
}

.select__toggle {
    cursor: pointer;
    padding: 0.375rem 0.75rem !important;
    line-height: inherit;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:hover {
        background-color: #f7f7f7;
    }

    .arrow__up {
        transform: rotate(180deg);
    }
}

.placeholder__color {
    color: #757575;
}

.multi__dropdown {
    top: 44px;
    border: 1px solid var(--default-dark);
    width: 100%;
    background-color: #FFF;
    max-height: 410px;
    overflow: auto;

    .multi__options {
        height: 40px;
        border-top: 1px solid var(--default-dark);
        padding: 0px 13px 0px 17px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;

        &:hover {
            background-color: #f7f7f7;
        }

        .check {
            width: 17px;
            height: 17px;
            border: 1px solid var(--default-dark);
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .check__active {
            background-color: var(--default-dark);
        }

        &:first-child {
            border-top: none;
        }
    }

}

.input__label {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 4px;

    span {
        color: #F04533;
        font-weight: bold;
    }

    ul {
        padding-left: 1.5rem;
    }
}

.close__btn {
    position: absolute;
    background-color: black;
    width: 18px;
    height: 18px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 8px;
    right: 10px;
    cursor: pointer;
    color: white;
    font-size: 14px;
    font-weight: 300;
    padding-left: .5px;
    padding-top: .5px;
}

.multi__select {
    right: 32px;
    top: 9px;
}

.footer__input {
    height: 42px;
    padding: 20px 16px;
    border-radius: 8px;
    border: none;
    outline: none;
    font-size: 16px;
}

@media only screen and (max-width:525px) {
    .close__btn {
        top: 10px
    }

    .multi__select {
        top: 11.5px;
    }
}