.login__container {
    width: 100%;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    overflow: hidden;

    .input__section {
        max-width: 621px;
        width: 100%;
        align-items: center;

        .heading_login {
            color: #080828;
            text-align: center;
            font-family: var(--PPEditorialOld);
            font-size: 65px;
            font-style: normal;
            font-weight: 200;
            line-height: 100%; 
        }

        .description {
            color: #080828;
            text-align: center;
            font-family: var(--IBMPlexSans);
            font-size: 25px;
            font-style: normal;
            font-weight: 400;
            line-height: 30px;
        }
        .expire__description{
            margin-top: 12px;
            margin-top: 12px;
        }

        .resend__btn {
            font-size: 15px !important;
            cursor: pointer;
            text-decoration: underline;
        }

        .custom__f__size {
            font-size: 15px !important;
        }
        .login__input{
            border-radius: 100px;
            border: 1px solid #000;
            background: #FFF;
            width: 513px;
            height: 57px;
            flex-shrink: 0;
            padding: 0px 8px 0px 20px;
            margin-top: 45px;
            display: inline-flex;
            align-items: center;
            gap: 10px;
            input{
                border: none;
                min-width: 60%;
            }
            input::placeholder{
                color: #080828;
                leading-trim: both;
                text-edge: cap;
                font-family: var(--IBMPlexMono);
                font-size: 16px;
                font-style: normal;
                font-weight: 300;
                line-height: 22px; /* 137.5% */
                letter-spacing: 0.8px;
                text-transform: uppercase;
            }
        }
       
    }
}

.navbar__login{
    position: absolute;
    z-index: 1;
    width: 100%;
}
.mobile_btn{
    display: none;
}
@media only screen and (max-width:525px) {
.mobile_btn{
    display: block;
}
.desktop_btn{
    display: none;
}

.login__container {
    .input__section {
        .heading_login{
            font-size: 50px;
        }
    }
    
}

}