.swiper {
  width: 100%;
  height: 100%;
  padding: 0px 224px;
  .swiper-wrapper {
    gap: 0px;
    align-items: flex-start !important;
    height: 100%;
  }
}

.swiper-slide {
  font-size: 18px;
  background: transparent;
  width: 350px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.swiper {
  width: 100%;
  min-height: 300px;
  margin: 20px auto;
}

.intro__card__main {
  width: 100%;
  border-radius: 20px;
  background-color: white;
  padding: 10px;
  min-height: 657px;
  &.companies__intro{
    min-height: 500px;
  }

  .review__section__new {
    .heading {
      font-family: var(--pp-formula-condensed);
      font-size: 65px;
      font-weight: 900;
      line-height: 87%;
      text-transform: uppercase;
      margin-top: 30px;
    }
    .description {
      font-family: var(--IBMPlexSans);
      font-size: 18px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: -0.396px;
      margin-top: 5px;
    }
    .list__section {
      margin-top: 5px;
      .list__ {
        margin-top: 10px;
        .bullet {
          width: 20px;
          height: 20px;
          border-radius: 100%;
        }
        .list__text {
          font-family: var(--PPEditorialOld);
          font-size: 25px;
          font-weight: 200;
          line-height: 30px;
          letter-spacing: -0.55px;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .swiper {
    padding: 0px 41px;
  }
  .swiper-slide {
    width: inherit !important;
  }
}

@media only screen and (max-width: 380px) {
  .intro__card__main{
    .review__section__new{
      .heading{
        font-size: 50px;
      }
    }
  }
}

@media only screen and (max-width: 320px) {
  .swiper {
    padding: 0px 25px;
  }
}
