.updates__main {
  width: 100%;
  color: var(--navy-blue);

  .heading__section {
    text-align: center;

    .btn__section {
      margin-top: 40px;
    }
  }

  .details__section {
    align-items: flex-end;

    .img__box {
      background-image: url("../../../assets/images/svgImages/curved.svg");
      background-size: 100% auto;
      background-position: bottom;
      background-repeat: no-repeat;
      display: flex;
      justify-content: center;
    }

    .text__section {
      padding: 30px 20px;
      background-color: white;

      .number {
        font-family: var(--pp-formula-condensed);
        font-size: 95px;
        font-style: normal;
        font-weight: 900;
        line-height: 87%;
        text-transform: uppercase;
      }

      .number__text {
        font-family: var(--PPEditorialOld);
        font-size: 48px;
        font-style: italic;
        font-weight: 200;
        line-height: 54px;
        letter-spacing: -1.056px;
        margin-top: 5px;
      }

      .details {
        font-family: var(--IBMPlexSans);
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        padding-left: 30px;
        border-left: 2px solid var(--navy-blue);
      }
    }
  }
}

.intro__card__section {
  width: 100%;
  margin-top: 92px;
  padding: 0px 224px;

  .intro__card {
    margin-top: 65px !important;
    display: flex;
    justify-content: space-between;

    .card__img__section {
      img {
        width: 100%;
        aspect-ratio: 1/1;
        border-radius: 33px;
      }
    }

    .card__img__section {
      width: calc(50% - 100px);
      padding: 0px;
      margin: 0px;

      img {
        width: 100%;
        aspect-ratio: 1/1;
      }
    }

    .card__text__section {
      width: calc(50% - 100px);
      padding: 0px;
      margin: 0px;

      .card__heading {
        font-family: var(--pp-formula-condensed);
        font-size: 65px;
        font-weight: 900;
        line-height: 87%;
        text-transform: uppercase;
        margin-top: 25px;
      }

      .card__description {
        font-family: var(--IBMPlexSans);
        font-size: 20px;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: -0.44px;
      }

      .list_ {
        display: flex;
        gap: 15px;
        margin-top: 15px;

        .bullet {
          width: 20px;
          height: 20px;
          background-color: yellow;
          border-radius: 100%;
        }

        .bullet__point {
          font-family: var(--PPEditorialOld);
          font-size: 30px;
          font-style: italic;
          font-weight: 200;
          line-height: 30px;
          letter-spacing: -0.66px;
        }
      }
    }
  }
}

.Member__Section {
  padding: 176px 0px 0px 0px;
  color: var(--navy-blue);

  .Member__main__heading {
    text-align: center !important;
    font-family: var(--PPEditorialOld);
    font-size: 65px;
    font-style: normal;
    font-weight: 200;
    line-height: 100%;
  }

  .button__main__section {
    margin-top: 32px;
    border-radius: 100px;
    border: 4px solid #fff;
    background: #fff;
    display: flex;
    justify-content: center;
    overflow-x: hidden;

    .pading__setting {
      padding-top: 60px !important;
    }

    .no_bg_no_border {
      outline: none;
      border: 1px solid transparent;
      background-color: transparent;
    }
  }

  .Card__main_section {
    height: 100%;
    padding-top: 60px;

    .Card__main {
      padding: 50px;
      color: var(--navy-blue);
      border-radius: 40px;
      background: #fff;

      .Cad__heading {
        text-align: center;
        font-family: var(--PPEditorialOld);
        font-size: 55px;
        font-style: normal;
        font-weight: 200;
        line-height: 100%;

        span {
          font-style: italic;
        }
      }

      .card__pricing__section {
        margin-top: 25px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        gap: 15px;
        align-self: stretch;

        .card__price {
          color: var(--navy-blue);
          font-family: var(--pp-formula-condensed);
          font-size: 75px;
          font-style: normal;
          font-weight: 900;
          line-height: 87%;
          /* 65.25px */
          text-transform: uppercase;
        }

        .Card__pricing_month {
          color: var(--navy-blue);
          font-family: var(--IBMPlexSans);
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 30px;
          /* 150% */
          letter-spacing: 1px;
          text-transform: uppercase;
        }
      }

      .btn__div {
        padding: 30px 0px;
      }

      .card__content {
        color: var(--navy-blue);
        text-align: center;
        font-family: var(--IBMPlexSans);
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px;
        opacity: 0.6;
      }
    }
  }
}

.slider__section {
  margin-top: 176px;

  .switch__custom {
    margin-top: 32px;
  }

  .button__main__section {
    margin-top: 32px;
    border-radius: 100px;
    border: 4px solid #fff;
    background: #fff;
    display: flex;
    justify-content: center;
    overflow-x: hidden;

    .pading__setting {
      padding-top: 60px !important;
    }

    .no_bg_no_border {
      outline: none;
      border: 1px solid transparent;
      background-color: transparent;
    }
  }
}

@media only screen and (max-width: 1439px) {
  .updates__main {
    .details__section {
      .text__section {
        padding: 30px 16px 30px 20px;
        border-radius: 0px 0px 10px 10px;

        .number {
          font-size: 65px;
        }

        .number__text {
          font-size: 48px;
          line-height: 54px;
        }

        .details {
          padding-left: 24px;
        }
      }
    }
  }

  .intro__card__section {
    padding: 0px 100px;

    .intro__card {

      .card__text__section,
      .card__img__section {
        width: calc(50% - 25px);
      }
    }
  }

  .Member__Section {
    padding: 151px 0px 0px 0px;

    .Card__main_section {
      .Card__main {
        height: 100%;

        .card__content {
          font-size: 16px;
          line-height: 25px;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .updates__main {
    .details__section {
      margin-top: 59px !important;
      gap: 25px;

      .text__section {
        .number {
          font-size: 55px;
        }

        .number__text {
          font-size: 30px;
          line-height: 54px;
        }

        .details {
          font-size: 18px;
        }
      }
    }
  }

  .intro__card__section {
    padding: 0px;

    .intro__card {
      display: block;

      .card__text__section,
      .card__img__section {
        width: 100%;
      }
    }
  }

  .Member__Section {
    padding: 124px 0px 0px 0px;

    .Card__main_section {
      .Card__main {
        padding: 30px;
        height: 100%;

        .card__content {
          font-size: 16px;
          line-height: 25px;
        }
      }
    }
  }
}

.switch__new {
  height: 43px !important;
  border: none;
  outline: none;
  background-color: #fff !important;

  .ant-switch-handle {
    width: 120px;
    border: none;
    outline: none;
    padding: 8px 20px 9px 20px !important;
    border-radius: 60px !important;
    background-color: var(--bg--yellow) !important;
    height: 43px !important;
    font-family: var(--IBMPlexMono);
    text-transform: uppercase;
    inset-inline-start: 0px !important; 

    &::before {
      padding: 9px 20px !important;
      border-radius: 60px !important;
      border: 1px solid var(--navy-blue);
      background-color: var(--bg--yellow) !important;
      color: var(--navy-blue) !important;
      width: 100% !important;
      font-size: 16px !important;
      text-transform: uppercase;
    }

    &.member {
      &::before {
        content: "Member";
      }
    }

    &.creator {
      &::before {
        content: "Creators";
      }
    }

    &:hover {
      background: linear-gradient(0deg,
          rgba(0, 0, 0, 0.1) 0%,
          rgba(0, 0, 0, 0.1) 100%),
        #e4ff00 !important;

      &::before {
        font-weight: 500;
        background: linear-gradient(0deg,
            rgba(0, 0, 0, 0.1) 0%,
            rgba(0, 0, 0, 0.1) 100%),
          #e4ff00 !important;
      }
    }
  }
}

.ant-switch-inner {
  width: 250px;
  background-color: #fff !important;
  height: 100% !important;

  &:hover {
    .ant-switch-inner-checked,
    .ant-switch-inner-unchecked {
      font-size: 16px !important;
      font-weight: 500 !important;
    }
  }
}

.ant-switch-checked {
  .ant-switch-handle {
    inset-inline-start: calc(100% - 123px) !important;
    top: 0px !important;
  }
}

.ant-switch {
  .ant-switch-handle {
    top: 0px !important;
  }
}

.ant-switch-inner-checked,
.ant-switch-inner-unchecked {
  width: 50%;
  width: 120px;
  width: 120px;
  border: none;
  outline: none;
  padding: 8px 20px !important;
  border-radius: 60px !important;
  font-size: 16px !important;
  height: 95% !important;
  font-family: var(--IBMPlexMono);
  text-transform: uppercase;
  display: flex !important;
  justify-content: center;
  align-items: center;
  color: var(--navy-blue) !important;
  pointer-events: all !important;
}

.ant-switch-inner-unchecked {
  position: absolute;
  right: 0;
  top: 23px;
}

.ant-switch-inner-checked {
  position: absolute;
  left: 2px;
}

.members_switch {
  .ant-switch-handle {
    &.annualy {
      &::before {
        content: "Annualy";
      }
    }

    &.monthly {
      &::before {
        content: "Monthly";
      }
    }
  }
}


@media only screen and (max-width:380px) {
  .updates__main {
    img {
      max-width: 100%;
    }

    .details__section {
      .text__section {
        .number {
          font-size: 45px;
        }
      }
    }
  }
}