.home__main {
    width: 100%;
    height: 100vh;
    background-color: var(--navy-blue);
    overflow: hidden;
    .loadingContainer{
        width: 100%;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0px;
        left: 0px;
        background-color: rgba($color: black, $alpha: .7);
        z-index: 9;
    }
}

.box__main__container {
    width: 100%;
    height: calc(100vh);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .box__container {
        background-color: var(--navy-blue);
        position: relative;
    }
}
.box__main__container__upper{
    width: 100%;
    height: calc(100vh);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 100px 180px 40px 180px;
    position: absolute;
    top: 0;
    left: 0;

    .box__container {
        background-color: var(--navy-blue);
    }
}

.image__box {
    width: 20px;
    height: 20px;
    border: .5px solid rgba(255, 255, 255, 0.2);
    overflow: hidden;

    .image {
        width: 20px;
        height: 20px;
        vertical-align: top;
        filter: grayscale(0%);
        transition: filter 0.5s ease;
        cursor: pointer;
        &:hover{
        filter: grayscale(100%);
        }
    }
}

.border__none {
    border: none !important;
}

.logo__image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

}

@media only screen and (max-width:1439px) {
    .box__main__container__upper{
        padding: 100px 60px 20px 60px;
    }
    
}

@media only screen and (max-width:768px) {
    .box__main__container__upper{
        padding: 80px 20px 20px 20px;
    }
    
}

@media only screen and (max-width:525px) {
      .logo__image {
        width: 270px;
    }
    .box__main__container__upper{
        position: absolute;
        top: 0;
        left: 0;
    
        .box__container {
            background-color: var(--navy-blue);
        }
    }
    
}