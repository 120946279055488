.top__header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    display: flex;
    padding: 0px 40px;

    .heading__section {
        display: flex;
        gap: 20px;
        align-items: center;
        height: 58px;

        .description {
            font-size: 15px;
            font-weight: 500;
            line-height: 18px;
            color: var(--purple) !important;
        }

        .center__line {
            width: 24px;
            height: 0px;
            border: 1px solid #D9DCE1;
            transform: rotate(90deg);
        }

        .close__icon {
            cursor: pointer;
        }

    }

    .logout__btn {
        font-size: 14px;
        font-weight: 500;
        border: none;
        outline: none;
        font-family: var(--sfpro);
        color: var(--default-dark);

        &:focus,
        &:active {
            border: none;
            outline: none;
        }
    }

}

.tab__container {
    width: 100%;
    padding: 0px 59px;

    .tab__section {
        border-bottom: 1px solid #E5E5E5;
        height: 48px;
        display: flex;
        align-items: flex-end;
        gap: 25px;

        .tab {
            font-size: 14px;
            font-weight: 500;
            color: #697386;
            padding-bottom: 9px;
            cursor: pointer;
            font-family: var(--sfpro);
        }

        .active__tab {
            color: var(--default-dark);
            border-bottom: 1px solid var(--default-dark);
            margin-bottom: -1px;
        }
    }
}

.profile__container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;

    .input__section {
        max-width: 406px;
        width: 406px;

        @media (max-width:400px) {
            width: 100%;
        }

        .heading {
            font-size: 34px;
            font-weight: 800;
            line-height: 39.75px;
        }

        .description {
            font-size: 15px;
            font-weight: 500;
            line-height: 15.5px;
        }
    }

    .short__width {
        width: 190px !important;
    }

    .attachment {
        width: 50px;
        height: 50px;
        position: relative;

        img {
            width: 100%;
            height: 100%;
        }

        .close__container {
            width: 14px;
            height: 13px;
            border-radius: 100%;
            background-color: #F04533;
            position: absolute;
            top: -5px;
            right: -5px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
        }
    }

    .verb__input {
        font-size: 14px;
        font-weight: 500;
    }

    .aboutUser__input {
        margin-top: 66px;
    }
    .mt-50{
        margin-top: 50px;
    }
    .save__btn{
        margin-top: 34px !important;
    }
}

@media only screen and (max-width:525px) {
    .top__header {
        padding: 0px 20px;
        gap: 10px;
    }

    .profile__container {
        padding: 0px 10px;

        .short__width {
            width: 100% !important;
        }
    }

}