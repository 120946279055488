.footer__new {
  color: var(--navy-blue);

  .sub__footer__section {
    height: 504px;
    position: relative;
    display: flex;
    align-items: center;
    background-color: #fff;

    .sub__footer__form{
      z-index: 999;
    }

    .stair__dots {
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: auto;
    }

    .sub__footer__heading {
      font-family: var(--PPEditorialOld);
      font-size: 55px;
      font-weight: 200;
      line-height: 55px;

      span {
        font-style: italic;
        position: relative;
        img {
          position: absolute;
          bottom: -20px;
          right: 12px;
        }
      }
    }

    .sub__prefix {
      margin-left: 27px;
      width: 67px;
      height: 24px;
    }

    .sub__footer__input__section {
      margin-top: 45px;
    }

    .point__section {
      margin-top: 19px;

      .bullet {
        width: 12px;
        height: 12px;
        background-color: #fc6d51;
        border-radius: 100%;
        margin-right: 10px;
      }

      .point__text {
        font-family: var(--IBMPlexSans);
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px;
        opacity: 0.6;
      }
    }
  }

  .bg__yellow {
    background-color: var(--bg--yellow);
  }

  .new__footer__section__main {
    width: 100%;
    padding: 81px 224px;

    .footer__list__heading {
      color: rgba(8, 8, 40, 0.6);
      font-family: var(--IBMPlexMono);
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0.8px;
      text-transform: uppercase;
    }

    .footer__list__text {
      margin-top: 8px;

      a {
        font-family: var(--IBMPlexSans);
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: -0.208px;
        text-decoration: none;
      }
      &:hover, a:hover {
        text-decoration: underline;
      }
    }
  }

  .copy__right__section {
    width: 100%;
    height: 80px;
    border-top: 1px solid var(--navy-blue);
    font-family: var(--IBMPlexSans);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0.28px;
    text-transform: uppercase;
    gap: 68px;
  }
}

@media only screen and (max-width: 1439px) {
  .footer__new {
    .sub__footer__section {
      height: 338px;
      .sub__footer__heading {
        font-size: 40px;
        line-height: 40px;
      }
    }
    .new__footer__section__main {
      padding: 81px 100px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .footer__main {
    padding: 0px 20px;

    .footer__form__section {
      height: auto;
      padding: 30px 0px;

      .info__section {
        justify-content: flex-start;
      }

      .form__section {
        margin-top: 20px;

        .feature__list {
          display: block;

          .feature {
            height: 30px;
            align-items: center;
          }
        }
      }
    }

    .footer__section {
      flex-wrap: wrap;
      height: auto;

      .footer__column {
        width: 50%;
        padding: 0px;
        border: none;
      }
    }
  }

  .footer__new {
    .sub__footer__section {
      height: auto;
      align-items: flex-start;
      padding: 49px 0px 187px 0px !important;

      .sub__footer__heading {
        font-size: 40px;
        line-height: 40px;
      }
      .stair__dots {
        bottom: 0;
        top: inherit;
        height: auto;
        width: 100%;
      }
    }
    .new__footer__section__main {
      padding: 81px 35px;
      .footer__list__section {
        margin-top: 81px !important;
        .col-md-4 {
          width: 50% !important;
        }
      }
      img{
        width: 149px;
      }
    }
    .copy__right__section {
      line-height: 16px;
      letter-spacing: 0.28px;
    }
  }
}

@media only screen and (max-width:380px){
  .footer__new{
    .new__footer__section__main{
      padding: 81px 20px;
    }
  }
}
