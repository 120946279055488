@font-face {
    font-family: 'Circular';
    src: url('CircularStd-Book.otf');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Circular';
    src: url('CircularStd-Light.otf');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Circular';
    src: url('CircularStd-Bold.otf');
    font-weight: bold;
    font-style: normal;
}

