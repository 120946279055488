@font-face {
    font-family: 'PlusJakartaSans';
    src: url('PlusJakartaSans-Medium.ttf');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'PlusJakartaSans';
    src: url('PlusJakartaSans-Light.ttf');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'PlusJakartaSans';
    src: url('PlusJakartaSans-Regular.ttf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'PlusJakartaSans';
    src: url('PlusJakartaSans-Bold.ttf');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'PlusJakartaSans';
    src: url('PlusJakartaSans-ExtraBold.ttf');
    font-weight: 800;
    font-style: normal;
}
