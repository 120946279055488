.moderation__main {
    width: 100%;
    min-height: 100vh;
    background-color: var(--navy-blue);

    .moderation__main__container {
        width: 100%;
        min-height: 100vh;
        display: flex;
        justify-content: center;
        padding: 110px 50px;

        .moderation__text__container {
            max-width: 850px;

            .moderation__main__heading {
                color: #FFF;
                font-family: var(--pp-formula-condensed);
                font-weight: 900;
                line-height: 90px;
                text-transform: uppercase;
                font-size: 104px;
                margin-top: 90px;
            }

            .moderation__main__description {
                color: #FFF;
                font-family: var(--PPEditorialOld);
                font-size: 35px;
                font-weight: 200;
                line-height: 54px;
                margin-top: 29px;
            }

            .schedule__cal__btn {
                outline: none;
                border-radius: 60px;
                border: 1px solid var(--navy-blue);
                background-color: var(--bg--yellow);
                padding: 8px 20px 9px 20px;
                color: var(--navy-blue);
                font-family: var(--IBMPlexMono);
                font-size: 16px;
                font-weight: 500;
                line-height: 22px;
                letter-spacing: 0.8px;
                text-transform: uppercase;
                margin-top: 26px;
            }

            .testimonial__section {
                margin-top: 90px;
                position: relative;

                .testimonial__box {
                    position: relative;
                    background: linear-gradient(to top, #101050 40%, lightgray 100%);
                    padding: 4px;
                    border-radius: 25px;

                    .navy__blue__bg {
                        background-color: var(--navy-blue);
                        border-radius: 25px;
                    }

                    .testimonial__inner__box {
                        padding: 46px 55px;
                        background: linear-gradient(180deg, rgba(255, 255, 255, 0.40) 0.55%, rgba(255, 255, 255, 0.00) 100%) !important;
                        backdrop-filter: blur(21px);
                        border-radius: 25px;

                        .testimonial__message {
                            color: #FFF;
                            font-family: var(--IBMPlexMono);
                            font-size: 22px;
                            font-weight: 400;
                            line-height: 31px;
                        }

                        .testimonial__name {
                            color: #FFF;
                            font-family: var(--IBMPlexMono);
                            font-size: 22px;
                            font-weight: 400;
                            margin-top: 57px;
                        }
                    }

                    .testimonial__commas {
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        border-bottom-left-radius: 25px;
                        overflow: hidden;
                    }
                }

            }

            .pagination__section {
                display: flex;
                justify-content: flex-end;
                margin-top: 40px;
            }
        }
    }
}


.border__none {
    border: none !important;
}


@media only screen and (max-width:525px) {
    .moderation__main {
        .moderation__main__container {
            padding: 110px 20px;

            .moderation__text__container {
                .moderation__main__heading {
                    margin-top: 0;
                    font-weight: 900;
                    line-height: 55px;
                    font-size: 60px;
                }

                .moderation__main__description {
                    font-size: 22px;
                    line-height: 34px;
                }

                .testimonial__section {
                    .testimonial__box {
                        .testimonial__inner__box {
                            padding: 20px;
                            .testimonial__message {
                                font-size: 20px;
                            }
    
                            .testimonial__name {
                                font-size: 20px;
                            }
                        }
                    }

                }
            }

            .testimonial__inner__box {
                padding: 20px;
            }
        }
    }
}

@media only screen and (max-width:320px) {
    .moderation__main {
        .moderation__main__container {
            .moderation__text__container {
                .testimonial__section {
                    .testimonial__box {
                        .testimonial__inner__box {
                            padding: 10px;
                        }
                    }

                }
            }
        }
    }
}