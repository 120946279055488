.ant-pagination {
    .ant-pagination-item {
        display: none !important;
    }
}

.pagination__btn {
    width: 70px;
    height: 70px;
    border-radius: 6px;
    background: rgba(255, 255, 255, 0.05);
    display: flex;
    align-items: center;
    justify-content: center;
}