.request__card {
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    gap: 15px;
    padding: 20px 20px 20px 28px;

    img {
        width: 66px;
        height: 66px;
        border-radius: 100%;
        object-fit: cover;
        cursor: pointer;
    }

    .user__name {
        color: #000;
        font-family: var(--IBMPlexSans);
        font-size: 15px;
        font-weight: 500;
        line-height: normal;
    }

    .btn__custom {
        min-width: 135px;
        font-size: 16px;
        font-weight: 500;
        color: var(--navy-blue);

        &.btn__gray {
            background-color: #F1F1F1;
        }
    }

}

.search__card__main {
    width: 100%;
    padding: 38px 30px;
    display: flex;
    gap: 18px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.20);

    .profile__image {
        width: 66px;
        height: 66px;
        object-fit: cover;
        border-radius: 100%;
    }

    .user__name {
        color: #000;
        font-family: var(--IBMPlexSans);
        font-size: 15px;
        font-weight: 500;
    }

    .user__question {
        color: var(--navy-blue);
        font-family: var(--pp-formula-condensed);
        font-size: 17px;
        font-weight: 900;
        line-height: 87%;
        text-transform: uppercase;
        margin-top: 17px;
    }

    .ans__list {
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        gap: 7px;

        .bullet {
            background-color: #FC6D51;
            width: 17px;
            height: 17px;
            border-radius: 100%;
        }

        .verb__point {
            display: flex;
            align-items: center;
            gap: 15px;
        }
    }

    .user__answer {
        color: var(--navy-blue);
        font-family: var(--PPEditorialOld);
        font-size: 17px;
        font-weight: 200;
        line-height: 30px;
        letter-spacing: -0.374px;
    }

    .meeting__section {
        display: flex;
        margin-top: 20px;
        gap: 9px;

        .attended__head {
            color: #000;
            font-family: var(--IBMPlexMono);
            font-size: 12px;
            font-weight: 500;
            line-height: 22px;
            letter-spacing: 0.6px;
            text-transform: uppercase;
        }

        .bt__container {
            display: flex;
            flex-wrap: wrap;
            gap: 8px;
        }

        .meeting__btn {
            border-radius: 60px;
            border: 1px solid #080828;
            background: #FFF;
            display: flex;
            height: 25px;
            padding: 8px 20px 9px 20px;
            align-items: center;
            font-family: var(--IBMPlexMono);
            font-size: 12px;
            font-weight: 500;
            line-height: 22px;
            letter-spacing: 0.6px;
            text-transform: uppercase;
        }
    }

    .bt__container__mob {
        display: none;
    }
}

.border__top {
    border-top: 1px solid rgba(0, 0, 0, 0.20);
}




//marketing card
.marketing__card__main {
    border-top: 1px solid #D9D9D9;
    padding: 53px 0px !important;

    .image__div {
        width: 496px;
        aspect-ratio: 12.4/11.95;
        border-radius: 20px;
        background: rgba(240, 232, 218, 0.60);

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 20px;
        }
    }

    .marketing_card_heading {
        color: var(--neutral-900, #171717);
        font-family: var(--pp-formula);
        font-size: 36px;
        font-weight: 700;
        line-height: 42px;
        letter-spacing: -0.792px;
    }

    .marketing_card_content {
        margin-top: 18px !important;


        .marketing_peaks {
            margin-top: 5px;

            .text_ {
                color: #000;
                font-family: var(--inter-font);
                font-size: 16px;
                font-weight: 400;
                line-height: 20px;
                letter-spacing: -0.208px;
            }

            img {
                width: 20px;
                height: 20px;
                margin-right: 14px;
            }
        }

        .marketing_end_content {
            position: absolute;
            bottom: 0;
            left: 0;
            font-family: var(--inter-font);
            padding-right: 20px;

            .marketing_end_content_content {
                color: rgba(0, 0, 0, 0.50);
                font-size: 24px;
                font-weight: 400;
                line-height: normal;
                max-width: 552px;
            }

            .explore__meetings__btn {
                margin-top: 15px;
                color: #000;
                font-size: 16px;
                font-weight: 500;
                line-height: 22px;
                letter-spacing: -0.208px;
                cursor: pointer;

                img {
                    width: 22px;
                    margin-left: 10px;
                }
            }
        }
    }
}

//pricing card
.pricing__card__main {
    width: 50%;
    max-width: 318px;

    .card__explore {
        padding: 27px 0px;
        width: 100%;
        min-height: 440px;
        flex-shrink: 0;
        border-radius: 12px;
        background: #F0E8DA;
    }

    .card__content {
        color: #000;
        text-align: center;
        font-family: var(--inter-font);
        font-size: 21px;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: -0.3px;
    }

    .card__price {
        margin-top: 20px;
        text-align: center;
        transform: rotate(0.194deg);
        color: #000;
        font-family: var(--inter-font);
        font-size: 16px;
        font-weight: 400;
        line-height: 26px;

        .pricing__dark {
            font-size: 48px;
            font-weight: 700;
            line-height: 60px;
            letter-spacing: -2px;
        }
    }

    .center__line {
        width: 100%;
        height: 1px;
        background-color: white;
        margin-top: 19px;
    }

    .card_second_section {
        margin-top: 32px;

        .cardContent_firsrt {
            margin-top: 14px;
            color: #000;
            text-align: center;
            font-family: var(--inter-font);
            font-size: 14px;
            font-weight: 400;
            line-height: 26px;
            opacity: 0.8999999761581421;
        }

        .explore__btn {
            font-family: var(--inter-font);
            font-size: 16px;
            font-weight: 600;
            margin-top: 36px;
            height: 56px;
            width: 200px;
            border-radius: 8px;
            background: #FFF;
            color: #000;
        }
    }

    &.bg__transparent {
        .card__explore {
            background-color: transparent;
            border: 1px solid #E5EAF1;
        }

        .center__line {
            background-color: #E5EAF1;
        }

        .card_second_section {
            .explore__btn {
                background: #000;
                color: #fff;
            }
        }
    }
}

@media only screen and (max-width:1138px) {
    .pricing__card__main {
        .card__explore {
            min-height: 300px;
        }

        .card__price {
            margin-top: 20px;
            font-size: 14px;
            line-height: 24px;

            .pricing__dark {
                font-size: 42px;
                line-height: 54px;
            }
        }

        .center__line {
            margin-top: 15px;
        }

        .card_second_section {
            margin-top: 15px;
            padding: 0px 10px;

            .cardContent_firsrt {
                font-size: 12px;
            }
        }
    }

}

@media only screen and (max-width:768px) {

    //marketing card
    .marketing__card__main {
        margin-top: 20px;

        .image__div {
            width: 100%;
            margin-top: 20px;
        }

        .marketing_card_content {
            .marketing_end_content {
                position: inherit;
                margin-top: 20px;

                .marketing_end_content_content {
                    font-size: 18px;
                }
            }
        }
    }
}


@media only screen and (max-width:525px) {
    .request__card {
        padding: 20px 10px;
      .btn__custom{
        min-width: 0;
      }
    }

    .search__card__main {
        .image__details {
            gap: 12px;

            .ques__ans {
                margin-top: 4px;
                line-height: 18px;

                .ans__list {
                    display: block;
                    margin-top: 5px;

                    .user__answer {
                        margin-left: 0px;
                        line-height: 18px;
                    }
                }
            }

            .bt__container {
                display: none;
            }
        }

        .bt__container__mob {
            display: flex;
            justify-content: space-between;

            .meeting__btn {
                min-height: 25px !important;
                font-size: 13px;
                line-height: 14px;
                margin-top: 11px;
                font-family: var(--secondary-font);
                font-weight: 600;
                width: 47%;
            }
        }
    }

    .pricing__card__main {
        width: 100%;
        max-width: 100%;

        .card__explore {
            width: 100%;
            min-height: 440px;
        }
    }
}