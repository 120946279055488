.meetings__main {
    width: 100%;
    min-height: 100vh;
    background-color: var(--navy-blue);

    .meetings__main__container {
        width: 100%;
        min-height: 100vh;
        display: flex;
        justify-content: center;
        padding: 110px 50px;

        .meetings__text__container {
            max-width: 850px;
            width: 100%;

            .meetings__main__heading {
                color: #FFF;
                font-family: var(--pp-formula-condensed);
                font-weight: 900;
                line-height: 90px;
                text-transform: uppercase;
                font-size: 104px;
                margin-top: 90px;
            }
            .meeting__tab{
                padding: 0;
                border: none;
                outline: none;
                background-color: transparent;
                color: white;
                font-weight: 700;
                font-family: var(--pp-formula);
                font-size: 14px;
                padding-bottom: 5px;
                &.active__meeting__tab{
                    border-bottom: 1px solid #fff;
                }
            }

            .meetings__main__description {
                color: #FFF;
                font-family: var(--PPEditorialOld);
                font-size: 35px;
                font-weight: 200;
                line-height: 54px;
                margin-top: 29px;
            }

            .schedule__cal__btn {
                outline: none;
                border-radius: 60px;
                border: 1px solid var(--navy-blue);
                background-color: var(--bg--yellow);
                padding: 8px 20px 9px 20px;
                color: var(--navy-blue);
                font-family: var(--IBMPlexMono);
                font-size: 16px;
                font-weight: 500;
                line-height: 22px;
                letter-spacing: 0.8px;
                text-transform: uppercase;
                margin-top: 26px;
            }

            .testimonial__section {
                margin-top: 30px;
                position: relative;
                display: flex;
                flex-direction: column;
                gap: 50px;

                .testimonial__box {
                    position: relative;
                    background: linear-gradient(to top, #101050 40%, lightgray 100%);
                    padding: 4px;
                    border-radius: 25px;
                    width: 100%;
                    min-height: 186px;
                    cursor: pointer;

                    .navy__blue__bg {
                        background-color: var(--navy-blue);
                        border-radius: 25px;
                        min-height: 186px;
                    }

                    .testimonial__inner__box {
                        background: linear-gradient(180deg, rgba(255, 255, 255, 0.40) 0.55%, rgba(255, 255, 255, 0.00) 100%) !important;
                        backdrop-filter: blur(21px);
                        border-radius: 25px;
                        display: flex;
                        min-height: 186px;

                        .date__section {
                            width: 157px;
                            border-radius: 25px 0px 0px 25px;
                            background: #FFF;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                            gap: 14px;

                            .month {
                                color: var(--navy-blue);
                                text-align: center;
                                font-family: var(--pp-formula);
                                font-size: 12px;
                                font-weight: 700;
                                line-height: 100%;
                                letter-spacing: 0.96px;
                            }

                            .date {
                                color: #25253F;
                                font-family: var(--pp-formula-condensed);
                                font-size: 64px;
                                font-weight: 900;
                                line-height: 100%;
                            }

                            .time {
                                color: #25253F;
                                font-family: var(--pp-formula);
                                font-size: 14px;
                                font-weight: 700;
                                line-height: 100%;
                                letter-spacing: 1.12px;
                            }
                        }

                        .title__section {
                            padding: 40px;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            gap: 14px;
                            width: calc(100% - 157px);

                            .location {
                                color: #FFF;
                                font-family: var(--pp-formula);
                                font-size: 10px;
                                font-weight: 700;
                                line-height: 150%;
                                letter-spacing: 1px;
                                text-transform: uppercase;
                            }

                            .title {
                                color: #FFF;
                                font-family: var(--IBMPlexMono);
                                font-size: 24px;
                                font-weight: 600;
                                line-height: 150%;
                            }
                        }
                    }
                }

            }

            .pagination__section {
                display: flex;
                justify-content: flex-end;
                margin-top: 40px;
            }
        }
    }
    .testimonial__btn{
        border: none;
        outline: none;
        padding: 0;
        background-color: transparent;
        text-align: start;
    }
}


.border__none {
    border: none !important;
}


@media only screen and (max-width:525px) {
    .meetings__main {
        .meetings__main__container {
            padding: 110px 20px;

            .meetings__text__container {
                .meetings__main__heading {
                    margin-top: 0;
                    font-weight: 900;
                    line-height: 55px;
                    font-size: 60px;
                }

                .meetings__main__description {
                    font-size: 22px;
                    line-height: 34px;
                }

                .testimonial__section {
                    .testimonial__box {
                        .testimonial__inner__box {
                            display: block;
                            .date__section{
                                width: 100%;
                                border-radius: 25px 25px 0px 0px;
                                padding: 20px;
                            }
                            .title__section{
                                width: 100%;
                                padding: 20px;
                                .title{
                                    font-size: 20px;
                                }
                            }
                        }
                    }

                }
            }
        }
    }
}