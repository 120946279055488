.hero__main {
    color: var(--navy-blue);
    padding: 40px 0px;
    align-items: center;

    .hero__heading {
        font-family: var(--pp-formula-condensed);
        font-size: 104px;
        font-weight: 900;
        line-height: 97%;
        text-transform: uppercase;

        span {
            font-style: italic;
            font-family: var(--PPEditorialOld);
            font-weight: 200;
            text-transform: none;
        }
    }

    .hero__description {
        font-family: var(--PPEditorialOld);
        font-size: 35px;
        font-style: normal;
        font-weight: 200;
        line-height: 107%;
        margin-top: 40px;
        margin-bottom: 40px;
        span{
            font-style: italic;
        }
    }
    .hero__image__section{
        img{
            max-width: 100%;
        }
    }
}

@media only screen and (max-width:1439px){
    .hero__main {
        .hero__heading {
            font-size: 85px;
            line-height: 87%;
        }
        .hero__description{
            font-size: 30px;
        }
    }
}

@media only screen and (max-width:768px){
    .hero__main {
        .hero__heading {
            margin-top: 40px;
            font-size: 75px;
        }
        .hero__description{
            font-size: 25px;
            line-height: 107%;
            margin-top: 20px;
            margin-bottom: 20px;
        }
        .hero__image__section{
            img{
                width: 100%;
            }
        }
    }
}

@media only screen and (max-width:380px){
    .hero__main {
        .hero__heading {
            margin-top: 40px;
            font-size: 60px;
        }
        .hero__description{
            font-size: 25px;
            line-height: 107%;
            margin-top: 20px;
            margin-bottom: 20px;
        }
        .hero__image__section{
            img{
                width: 100%;
            }
        }
    }
}