.about__main {
    width: 100%;
    min-height: calc(100vh - 100px);
    background-color: var(--navy-blue);

    .about__main__container {
        width: 100%;
        min-height: calc(100vh - 100px);
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0px 50px;

        .about__text__container {
            max-width: 1040px;

            .about__main__heading {
                color: #FFF;
                font-family: var(--PPEditorialOld);
                font-size: 35px;
                font-weight: 200;
                line-height: 54px;

                &.m__top {
                    margin-top: 46px;
                }

                span {
                    color: #FFF;
                    font-family: var(--pp-formula-condensed);
                    font-weight: 900;
                    line-height: 90px;
                    text-transform: uppercase;
                    font-size: 104px;
                }

                a {
                    color: #FFF;
                    font-family: var(--PPEditorialOld);
                    font-size: 35px;
                    font-weight: 200;
                    line-height: 54px;
                    text-decoration: underline;
                }
            }
        }
    }
}


.border__none {
    border: none !important;
}


@media only screen and (max-width:525px) {
    .about__main {
        .about__main__container {
            padding: 0px 20px;

            .about__text__container {
                .about__main__heading {
                    font-size: 22px;
                    line-height: 34px;

                    &.m__top {
                        margin-top: 46px;
                    }

                    span {
                        font-weight: 900;
                        line-height: 55px;
                        font-size: 60px;
                    }

                    a {
                        font-size: 22px;
                        line-height: 34px;
                    }
                }
            }
        }
    }
}