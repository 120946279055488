.bg__grey{
    background-color: #F5F5F5;
}
.content__container {
    overflow-y: auto;

    .content__main {
        min-height: calc(100vh - 164px);
        padding: 0px 40px;


        @media (max-width:768px) {
            padding: 0px 20px;
        }

        @media (max-width:525px) {
            padding: 0px 10px;
        }
    }
    @media (max-width:768px) {
        height: auto
    }
}